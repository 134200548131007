* {
    margin: 0;
    padding: 0;
    font-family: "Open Sans", sans-serif;
    scroll-behavior: smooth;
    text-decoration: none;
}

body {
    margin: 0;
    font-family: 'montserrat';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px !important;
    overflow-x: hidden;
}

.active{
    position: relative;   
}

.active::before{
    position: absolute;
    z-index: -1;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: -25%;
    clip-path: polygon(30% 0, 100% 0%, 70% 100%, 0% 100%);
    background: linear-gradient(to right, #FFC756, #FEA900);
}


::-webkit-scrollbar {
    width: 12px;
}


::-webkit-scrollbar-thumb {
    width: 8px;
    background: #999;
    border-radius: 20px;
    border-right: 3px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
background: #666;
}


.circles{
    animation: rotate 20s infinite linear;
    position: absolute;
    top: 50%;
    left: 50%;
}

@keyframes rotate {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}